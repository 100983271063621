@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

@media print {
  @page {
    size: auto;
    padding: 20mm 10mm 10mm 10mm !important;
    margin: 20mm 10mm 10mm 10mm !important;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    page-break-before: auto;
    /* page-break-inside: avoid; */
  }
}

@media print {
  .print-hide {
    display: none;
  }
  .print-show {
    display: block !important;
  }
  .print-only {
    display: block !important;
  }
  header,
  footer {
    display: none !important;
  }
}

.print-show {
  display: none;
}

.print-only {
  display: none;
}

